<template>
  <div class="project-theme">
    <immoComponent v-if="getCurentProjectTypeImmo"></immoComponent>
    <piplineComponent v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getCurentProjectType', 'getCurentProjectTypeImmo'])
  },
  components: {
    immoComponent: () => import('./immobilier/pipline/index.vue'),
    piplineComponent: () => import('./index.vue')
  }
}
</script>

<style lang="scss" scoped>
.project-theme {
  height: 100%;
}
</style>
